<template>
  <div>
    <h4 class="font-bold text-[18px] lg:text-[30px] uppercase text-green-swamp">
      {{ t('youtube_header') }}
    </h4>
    <p class="text-[12px] lg:text-sm text-gray-mud mt-4 lg:mt-5">
      {{ t('youtube_description') }}
    </p>
    <div class="grid md:grid-cols-2 mt-5 lg:mt-10 gap-5 lg:gap-7">
      <div
        v-for="item in items"
        :key="item.href"
        class="flex relative min-h-[222px] md:min-h-[280px]"
      >
        <nuxt-img
          :src="item.img"
          format="webp"
          width="556"
          height="300"
          class="block xl:hidden w-full"
          loading="lazy"
        />
        <nuxt-img
          :src="item.wideImg"
          format="webp"
          width="692"
          height="280"
          class="hidden xl:block w-full object-cover"
          loading="lazy"
        />
        <IconsIbisOutline
          class="absolute top-0 right-0 h-full"
          :class="item.fillClass"
        />
        <div
          class="absolute top-3 right-3 md:top-7 md:right-7 flex flex-col gap-1.5 md:gap-3 items-end text-white"
        >
          <span class="text-[10px] sm:text-sm">YouTube</span>
          <span
            class="text-sm xs:text-[27px] md:text-xl 2xl:text-[27px] text-right font-semibold w-36 xs:w-56 xs:leading-7"
          >
            {{ item.title }}
          </span>
        </div>
        <a
          :href="item.href"
          target="_blank"
          class="absolute bottom-3 right-3 md:bottom-7 md:right-7 text-[10px] xs:text-sm bg-white rounded py-2 px-3.5 xs:px-5 xs:py-3.5 font-semibold"
        >
          {{ t('Перейти на канал') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import find from 'lodash/find'
import { storeToRefs } from 'pinia'
import { useSettingsStore } from '~/stores/settings'

const { t } = useI18n()
const { allProjectsSettings } = storeToRefs(useSettingsStore())

const getValue = (group, name, defaultValue = '') =>
  find(
    allProjectsSettings.value,
    el => el.group === group && el.propertyName === name
  )?.propertyValue || defaultValue

const items = computed(() => {
  const data = [
    {
      href: getValue(SETTINGS.GROUP_WEAPON, SETTINGS.YOUTUBE_LINK, '#'),
      img: '/images/about/channel-1-xl.png',
      wideImg: '/images/about/channel-1-xl.png',
      fillClass: 'text-orange',
      title: getValue(SETTINGS.GROUP_WEAPON, SETTINGS.PROJECT_TITLE)
    },
    {
      href: getValue(SETTINGS.GROUP_FISHING, SETTINGS.YOUTUBE_LINK, '#'),
      img: '/images/about/channel-2-xl.png',
      wideImg: '/images/about/channel-2-xl.png',
      fillClass: 'text-green-ibis',
      title: getValue(SETTINGS.GROUP_FISHING, SETTINGS.PROJECT_TITLE)
    }
  ]
  return useStoreType().isHunting ? data : data.reverse()
})
</script>
